@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::before,
*::after {
  box-sizing: border-box;
}
a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
button {
  background-color: transparent;
  color: inherit;
  border-width: 0;
  padding: 0;
  cursor: pointer;
}
figure {
  margin: 0;
}
input::-moz-focus-inner {
  border: 0;
  padding: 0;
  margin: 0;
}
ul,
ol,
dd {
  margin: 0;
  padding: 0;
  list-style: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
}
p {
  margin: 0;
}
cite {
  font-style: normal;
}
fieldset {
  border-width: 0;
  padding: 0;
  margin: 0;
}
body,
html {
  margin: 0;
  padding: 0;
}

input {
  /* -webkit-appearance: none; */
}

iframe#launcher {
  display: none;
}

body {
  /** For iPhone X > */
  padding-left: constant(safe-area-inset-left);
  padding-right: constant(safe-area-inset-right);
}

body .product-detail-price {
  display: none;
}

body.pageDisplay .product-detail-price {
  display: flex;
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari, and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge, and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

:root {
  --reach-dialog: 1;
  --reach-menu-button: 1;
  --reach-listbox: 1;
  --reach-tabs: 1;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

#__next {
  position: relative;
}

/* Used to detect in JavaScript if apps have loaded styles or not. */
:root {
  --reach-dialog: 1;
}

[data-reach-dialog-overlay] {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
}

[data-reach-dialog-content] {
  overflow: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: hsla(0, 0%, 0%, 0.33);
  display: flex;
  align-items: flex-start;
}

.scrollbar-hidden::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}

.scrollbar-hidden {
  scrollbar-width: none;
}

/* style used in the notifications, this will pause the animation in the :after element when the user hovers the notification */
@media (hover: hover) and (pointer: fine) {
  .pause-on-hover:hover:after {
    animation-play-state: paused;
  }
}

/* Adyen checkout
GENERAL */
.adyen-parent {
  button.adyen-checkout__button {
    border-radius: 0;
    height: 3rem;
    background: #182f40;
    transition: all 0.2s ease;
    font-family: 'Artegra Sans Extended', 'Arial', 'sans-serif';
    letter-spacing: 0.28em;
    text-transform: uppercase;
    font-size: 9px;
    margin-top: 0.5rem;

    @media (min-width: 768px) {
      height: 3.5rem;
      font-size: 11px;
    }

    &:hover,
    &:focus {
      background: #2d4353;
      box-shadow: none !important;
    }
  }
}

/* Adyen checkout
IDEAL */
#adyen-ideal {
  .adyen-checkout__dropdown__button,
  .adyen-checkout__dropdown__button--active {
    padding: 0 !important;
    height: 3rem;
    border: 0 !important;
    box-shadow: none !important;

    &:focus {
      box-shadow: none !important;
    }

    @media (min-width: 768px) {
      height: 3.5rem;
    }
  }

  input {
    border: 1px solid #bdbdbd;
    padding: 0 0.75rem;
    margin-bottom: 0;
    height: 100%;
    transition: all 0.2s ease;

    &:hover,
    &:focus {
      border: 1px solid #141414;
    }

    &:focus {
      box-shadow: rgba(120, 93, 66, 0.2) 0px 0px 0px 3px;
    }

    :placeholder {
      opacity: 0;
    }
  }

  .adyen-checkout__dropdown__list {
    margin-top: 0 !important;
    border-radius: 0 !important;

    li {
      display: flex;
      flex-direction: row-reverse;
    }
  }
}

/* Adyen checkout
CREDIT CARD */
#adyen-scheme {
  .adyen-checkout__label {
    background: white;
    height: 3rem;
    margin-bottom: 0.5rem;
    padding: 0 0.75rem !important;
    position: relative;

    .adyen-checkout__label__text {
      display: none;
    }
  }

  .adyen-checkout-form-instruction {
    font-size: 0.625rem;
    color: #909090;
    margin-bottom: 0.25rem;

    @media (min-width: 768px) {
      font-size: 0.75rem;
    }
  }

  .adyen-checkout__input-wrapper {
    height: 100%;
  }

  .adyen-checkout__input {
    box-shadow: none !important;

    &:hover {
      border: 1px solid #141414;
    }
  }

  .adyen-checkout__input--focus {
    border: 1px solid #141414;
    box-shadow: rgba(120, 93, 66, 0.2) 0px 0px 0px 3px !important;
  }

  .adyen-checkout__card__exp-cvc {
    grid-template-columns: 1fr 1fr !important;
    width: 100%;
  }

  .adyen-checkout__card__brands {
    margin-bottom: 0.5rem;
  }

  .adyen-checkout__field--expiryDate,
  .adyen-checkout__field--securityCode {
    grid-column: auto !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
    width: 100% !important;
  }
}

/* Align listbox on the top of the input on mobile */
.align-mobile-listbox-content-top {
  @media (max-width: 767px) {
    .listbox-menu {
      bottom: unset !important;
      top: 0;
      transform: translateY(-100%);
    }
  }
}

.complete-social-registration {
  .gigya-screen.portrait {
    padding: 0;
    margin-left: 0;
  }
}

/* Style for homepage hero indicators */
.hero-indicator-circle {
  stroke-dashoffset: 201px;
  stroke-dasharray: 201px;
}

/* Style for Rolex input labels */
.rolex-input-labels-transform:not(:placeholder-shown),
.rolex-input-labels-transform:focus {
  & + label {
    top: 0.25rem;
  }
}
.rolex-select-labels-transform-has-value,
.rolex-select-labels-transform:focus {
  & + label {
    top: 0.25rem;
  }
}

/* Style for Rolex nav */
.rolex-mobile-nav {
  display: flex;

  @media (min-width: 1240px) {
    display: none;
  }
}
.rolex-desktop-nav {
  display: none;

  @media (min-width: 1240px) {
    display: flex;
  }
}

@layer components {
  .tw-text-with-links a {
    @apply text-rolexPrimary underline transition-colors duration-150 hover:text-rolexPrimary/60 focus:text-rolexPrimary/60 focus:outline-rolexPrimary;
  }
}
